<template>
  <div>
    <CCard v-if="isAdmin()">
      <CCardHeader> <CIcon name="cil-grid" /> 統計資料 </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="4">
            <CButton
              class="ml-3"
              color="primary"
              variant="outline"
              square
              @click="downloadFavorites(false)"
            >
              店舗別お気に入り登録人数
            </CButton>
          </CCol>
          <CCol sm="4">
            <CButton
              class="ml-3"
              color="primary"
              variant="outline"
              square
              @click="downloadFavorites(true)"
            >
              お気に入り（ローデータ）
            </CButton>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol sm="4">
            <CButton
              class="ml-3"
              color="primary"
              variant="outline"
              square
              @click="downloadFavoriteStoreCount(false)"
            >
              お気に入り店舗登録数別人数
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import firebase from "@firebase/app";
import { UserRole } from '@/common/const';

export default {
  name: "Statistics",
  data() {
    return {
      account: {}
    };
  },
  methods: {
    async init() {
      let uid = firebase.auth().currentUser.uid
      let event = firebase.app().functions('asia-northeast1').httpsCallable('me')
      this.$store.commit("showLoading")
      // ユーザーデータ取得
      await event({uid: uid}).then(function(res) {
        this.account = res.data.user
        this.$store.commit("hideLoading")
      }.bind(this));
    },
    async downloadFavorites (isRaw) {
      let rawCsv = "";
      const memberSnapshot = await firebase.firestore().collection("members").get();
      const storeSnapshot = await firebase.firestore().collection("stores").get();
      const stores = storeSnapshot.docs.map((doc) => doc.data());
      let storeDatas = [];
      for (const doc of memberSnapshot.docs) {
        const favorites = doc.data().favorites;
        if (favorites == null) continue;
        for (let [key, value] of Object.entries(favorites)) {
          let row = `${key},${value}\n`;
          rawCsv += row;
          if (key == null) continue;
          let store = stores.find(s => s.id == key);
          if (store == null) {
            store = {
              storeCode: '000000',
              name: '（削除済み）',
              count: 1,
            }
          }
          const storeCode = ("000000" + store.storeCode).slice(-6);
          if (storeDatas.some(sd => sd.storeCode === storeCode)) {
            storeDatas.forEach(sd => {
              if (sd.storeCode === storeCode) {
                sd.count++;
              }
            });
          } else {
            storeDatas.push({
              storeCode: storeCode,
              storeName: store.name,
              count: 1,
            });
          }
        }
      }
      let csv = "";
      csv = '\ufeff' + '店舗コード,店舗名,人数'
      csv += '\n'
      storeDatas.sort((a, b) =>  Number(a.storeCode) - Number(b.storeCode))
        .forEach(sd => {
          let line = sd.storeCode + "," +
                    sd.storeName + "," +
                    sd.count
          csv += line + '\n'
      });

      let target = isRaw ? rawCsv : csv;
      let fileName = isRaw ? 'RawFavorites.csv' : 'Favorites.csv';
      let blob = new Blob([target], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    async downloadFavoriteStoreCount () {
      const memberSnapshot = await firebase.firestore().collection("members").get();
      const storeSnapshot = await firebase.firestore().collection("stores").get();
      const stores = storeSnapshot.docs.map((doc) => doc.data());
      let favoriteStoreCounts = [];
      for (const doc of memberSnapshot.docs) {
        const favorites = doc.data().favorites;
        if (favorites == null) continue;
        let favoriteCnt = Object.keys(favorites).filter(k => stores.some(s => s.id == k)).length;
        favoriteStoreCounts.push(favoriteCnt);
      }

      let favoriteStoreCountGroup = favoriteStoreCounts.reduce((obj, cur) => {
        if (!obj[cur]) obj[cur] = 0;
        obj[cur]++;
        return obj;
      }, {});

      let csv = "";
      csv = '\ufeff' + 'お気に入り登録店舗数,人数'
      csv += '\n'
      Object.keys(favoriteStoreCountGroup).sort(k => Number(k) - Number(k))
        .forEach(k => {
          let line = k + "," +`${favoriteStoreCountGroup[k]}`;
          csv += line + '\n'
      });

      let target = csv;
      let fileName = 'FavoriteStoreCountGroup.csv';
      let blob = new Blob([target], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    isAdmin() {
      return this.account.role == UserRole.ADMIN;
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
};
</script>
