var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAdmin()
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-grid" } }),
                  _vm._v(" 統計資料 ")
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                color: "primary",
                                variant: "outline",
                                square: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadFavorites(false)
                                }
                              }
                            },
                            [_vm._v(" 店舗別お気に入り登録人数 ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                color: "primary",
                                variant: "outline",
                                square: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadFavorites(true)
                                }
                              }
                            },
                            [_vm._v(" お気に入り（ローデータ） ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "CCol",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                color: "primary",
                                variant: "outline",
                                square: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadFavoriteStoreCount(false)
                                }
                              }
                            },
                            [_vm._v(" お気に入り店舗登録数別人数 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }